

import {Vue} from "vue-class-component"
import "../css/NotFound/NotFound.css"
enum MoveState{
    MOVINNG,
    FINISHED
}
export default class NotFound extends Vue{
  
}
