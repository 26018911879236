
import "../../css/components/imageShow/imageShow.css";
import { Vue, Options } from 'vue-class-component';
import { ElMessageBox } from "element-plus";
export class V2 {
    public x: number;
    public y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}
export function v2(x: number, y: number): V2 {
    return new V2(x, y);
}
export type Color = {
    r: number,
    g: number,
    b: number,
    a: number
}
export type ImageConfig = {
    title?: {
        str: string,
        lineWidth: string,
        color: Color,
        textColor: Color,
        fontSize: string
    };
    marginTop?: string,
    desc?: {
        fontSize: string,
        opacity: string,
        fontWeight: string,
        str: string[]
    },
    bottomFontSetting: {
        fontSize: string,
        fontWeight: string,
        color: Color
    }
    imageList?: string[];
    imageHasTip?: boolean
    bottomTip?: string;
    bottomTipOpacity?: string,
    imageHasBorder?: boolean
    imageContent?: {
        desc: string
        pos: V2;
        width: string;
        color?: Color
        fontSize: number,
        url: string
    }
}
enum MoveState {
    MOVINNG,
    FINISHED
}
@Options({
    props: {
        imageData: Object,
    }
})
export default class ImageShow extends Vue {
    public imageData: ImageConfig;
    private bottomTip: HTMLElement;
    private self: HTMLElement;
    private imageDesc: HTMLElement;
    private titleDesc: HTMLElement;
    private titleText: HTMLElement;
    private lineLeft: HTMLElement;
    private lineRight: HTMLElement;
    private imgBody: HTMLElement;
    private currentPosX: number = 0;
    private leftArrow: HTMLElement;
    private rightArrow: HTMLElement;
    private view: HTMLElement;
    private startX: number = null;
    private offset: number = 0;
    private moveState: MoveState = MoveState.FINISHED;
    private GS_viewPort: V2 = { x: 375, y: 375 };
    private get viewPort(): V2 {
        return this.GS_viewPort;
    }
    private set viewPort(value: V2) {
        this.GS_viewPort = value;
        this.view.parentElement.style.width = value.x + "px";
        this.view.parentElement.style.height = value.y + 'px';
    }

    private GS_Page: number = 0;
    private widthCache: number[] = [];
    private get page() {
        return this.GS_Page;
    }
    private set page(value: number) {
        if (this.moveState == MoveState.FINISHED && value < this.view.children.length && value >= 0) {
            this.GS_Page = value;
            this.movePage(value);
        }
    }
    public override mounted() {
        this.view = <HTMLElement>this.$refs.view;
        this.rightArrow = <HTMLElement>this.$refs.rightArrow;
        this.imgBody = <HTMLElement>this.$refs.imgBody;
        this.leftArrow = <HTMLElement>this.$refs.leftArrow;
        this.titleText = <HTMLElement>this.$refs.titleText;
        this.self = <HTMLElement>this.$refs.self;
        this.bottomTip = <HTMLElement>this.$refs.bottomTip;
        this.imageDesc = <HTMLElement>this.$refs.imageDesc;
        this.titleDesc = <HTMLElement>this.$refs.titleDesc;
        this.lineLeft = <HTMLElement>this.$refs.lineLeft;
        this.lineRight = <HTMLElement>this.$refs.lineRight;
        this.self.style.marginTop = this.imageData.marginTop;
        if (this.view) {
            this.view.style.transform = `translateX(${this.currentPosX}px)`;
            for (let i = 0; i < this.view.children.length; ++i) {
                let imgNode = this.view.children[i].children[0] as HTMLElement;
                imgNode.onload = () => {
                    let page = imgNode.parentElement;
                    if (this.imageData.imageHasBorder) {
                        page.style.border = "solid 4px #808080";
                        page.style.borderRadius = "10px";
                    }
                    page.style.width = imgNode['naturalWidth'] > this.imgBody.clientWidth ? this.imgBody.clientWidth + 'px' : imgNode['naturalWidth'] + 'px';
                    this.imgBody.style.width = page.style.width;
                    this.widthCache[i] = this.imgBody.clientWidth;
                    this.GS_viewPort.x = this.widthCache[i];
                }
            }
            if (this.imageData.imageHasTip && this.leftArrow && this.rightArrow) {
                this.leftArrow.style.display = 'inline';
                this.rightArrow.style.display = 'inline';
                this.rightArrow.onclick = () => { this.page++; }
                this.leftArrow.onclick = () => { this.page--; }
            }
            if (this.imageData.imageContent.desc) {
                this.imageDesc.style.top = this.imageData.imageContent.pos.y + "px";
                this.imageDesc.style.left = this.imageData.imageContent.pos.x + "px";
                if (this.imageData.imageContent.color) {
                    let color = this.imageData.imageContent.color;
                    this.imageDesc.style.color = `rgba(${color.r},${color.g},${color.b},${color.a})`;
                }
                this.imageDesc.style.width = this.imageData.imageContent.width;
            }
            if (this.imageData.imageContent.url) {
                this.imageDesc.style.textDecoration = 'underline';
                this.imageDesc.innerHTML = this.imageData.imageContent.desc;
            }
        }
        if (this.imageData.desc) {
            for (let index = 0; index < this.titleDesc.children.length; index++) {
                let element = this.titleDesc.children[index] as HTMLElement;
                element.style.opacity = this.imageData.desc.opacity;
                element.style.fontWeight = this.imageData.desc.fontWeight;
                element.style.fontSize = this.imageData.desc.fontSize;
            }
        }
        if (this.imageData.bottomTip && this.imageData.bottomFontSetting) {
            for (let index = 0; index < this.bottomTip.children.length; index++) {
                let element = this.bottomTip.children[index] as HTMLElement;
                element.style.opacity = this.imageData.bottomTipOpacity;
                element.style.fontSize = this.imageData.bottomFontSetting.fontSize;
                element.style.fontWeight = this.imageData.bottomFontSetting.fontWeight;
                element.style.color = `rgb(${this.imageData.bottomFontSetting.color.r},${this.imageData.bottomFontSetting.color.g},${this.imageData.bottomFontSetting.color.b})`;
            }
        }
        if (this.lineLeft && this.imageData.title) {
            this.lineLeft.style.width = this.imageData.title.lineWidth;
            this.lineRight.style.width = this.imageData.title.lineWidth;
            let color = this.imageData.title.color;
            if (color) {
                this.lineLeft.style.backgroundColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
                this.lineRight.style.backgroundColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
            }
            color = this.imageData.title.textColor;
            if (color) {
                this.titleText.style.color = `rgba(${color.r},${color.g},${color.b},${color.a})`;
            }
            if (this.imageData.title.fontSize) {
                this.titleText.style.fontSize = this.imageData.title.fontSize;
            }
        }
        if (this.imageData.imageList.length > 1) {
            this.view.ontouchstart = this.touchStart.bind(this);
            this.view.ontouchmove = this.touchMove.bind(this);
            this.view.ontouchend = this.touchEnd.bind(this);
            this.view.ontouchcancel = this.touchEnd.bind(this);
        }
    }


    public ToUrl() {
        ElMessageBox.alert('正在获取您的位置...', '提示');
        navigator.geolocation.getCurrentPosition((pos) => {
            ElMessageBox.alert('正在前往导航...', '提示');
            let url = `https://www.amap.com/dir?from[name]=当前位置&from[lnglat]=${pos.coords.longitude},${pos.coords.latitude}&to[name]=硕丰园酒楼(马路湾店)&to[lnglat]=104.71913293004039,31.761004924834094&policy=1&type=car`
            ElMessageBox.close();
            window.open(url);
        }, () => {
            ElMessageBox.close();
            ElMessageBox.alert('请授权获取您的位置信息,我们将为您构建路径', '提示', {
                confirmButtonText: 'OK',
                callback: (action: any) => {
                    if (action == 'confirm') {
                        this.ToUrl();
                    }
                },
            })
        })
    }
    private easeInOutQuad(currentTime: number, startValue: number, changeValue: number, duration: number) {
        currentTime /= duration / 2;
        if (currentTime < 1) return changeValue / 2 * currentTime * currentTime + startValue;
        currentTime--;
        return -changeValue / 2 * (currentTime * (currentTime - 2) - 1) + startValue;
    }
    private movePage(pageNum: number) {
        let calcPagePos = (num: number) => {
            let result = 0;
            if (num != 0) {
                let i = 0;
                while (this.widthCache[i] && i < num) {
                    result += this.widthCache[i];
                    i++;
                }
            }
            return result;
        }
        this.moveState = MoveState.MOVINNG;
        let time = Date.now(), thisPosX = Math.abs(this.currentPosX), thisPage = pageNum;
        let timer = setInterval(() => {
            let useTime = Date.now() - time;
            if (useTime > 480) useTime = 480;
            let curPos = this.easeInOutQuad(useTime, thisPosX, calcPagePos(thisPage) - thisPosX, 480);
            this.view.style.transform = `translateX(${-curPos}px)`;
            if (useTime == 480) {
                this.currentPosX = curPos;
                this.offset = 0;
                clearInterval(timer);
                this.moveState = MoveState.FINISHED;
            }
        }, 4)
    }
    startY: number;
    touchStart(event: TouchEvent) {
        if (this.moveState != MoveState.FINISHED || event.changedTouches.length != 1) return;
        this.startX = event.changedTouches[0].clientX;
        this.startY = event.changedTouches[0].clientY;
    }
    touchMove(event: TouchEvent) {
        if (this.moveState != MoveState.FINISHED || event.changedTouches.length != 1) return;
        this.offset = -(event.changedTouches[0].clientX - this.startX);
        if (Math.abs(this.offset) > 20) {
            document.body.style.overflowY = 'hidden';
            if (Math.abs(this.offset) > this.viewPort.x) {
                this.offset = this.offset > 0 ? this.viewPort.x : -this.viewPort.x;
            }
            if (this.currentPosX + this.offset > this.view.scrollWidth - this.viewPort.x) {
                this.offset = this.view.scrollWidth - this.viewPort.x - this.currentPosX;
                this.view.style.transform = `translateX(${-(this.view.scrollWidth - this.viewPort.x)}px)`;
            } else if (this.currentPosX + this.offset < 0) {
                this.offset = -this.currentPosX;
                this.view.style.transform = `translateX(${0}px)`;
            } else {
                this.view.style.transform = `translateX(${-(this.currentPosX + this.offset)}px)`;
            }
            event.preventDefault();
        }
    }
    touchEnd(event: TouchEvent) {
        if (this.moveState != MoveState.FINISHED || event.changedTouches.length != 1) return;
        this.currentPosX = this.currentPosX + this.offset;
        if (this.offset > 30) {
            this.page++;
        } else if (this.offset < -30) {
            this.page--;
        } else {
            this.page = this.page;
        }
        document.body.style.overflowY = 'auto';
    }
}
