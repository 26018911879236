export type CM=
{
    user_token:string;
}
export enum UserSex
{
    NONE,
    MALE,
    FEMALE
}
export interface UserConstrator
{
    nick_name:string,
    user_id:number
}
export interface LocalUserConstrator extends UserConstrator
{
    token:string
}
export abstract class User_Base
{
    public nick_name:string;
    public user_id:number;
    public sex:UserSex = UserSex.NONE;
    public abstract SetUserInfo(param:UserConstrator):void;
}
export class UserLocal extends User_Base
{
    private constructor()
    {
        super();
        let localCM:CM = JSON.parse(localStorage.getItem("Local_CoolArrServices"));
        this._token = localCM && localCM.user_token?localCM.user_token:"";
    }

    private static self:UserLocal;

    private _token:string = "";
    public get token()
    {
        return this._token;
    }
    public set token(value:string)
    {
        this._token = value;
    }

    public static get instacne():UserLocal
    {
        if(!UserLocal.self)
        {
            UserLocal.self = new UserLocal();
        }
        return UserLocal.self;
    }

    public SetUserInfo(param:LocalUserConstrator) 
    {
        this.token = param.token;
        this.nick_name = param.nick_name;
        this.user_id = param.user_id;
    }
}