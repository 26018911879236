
import {
    Options,
    Vue
} from 'vue-class-component';
import { MeetStoreGetter} from '@/store';
import "../css/MerryView/merry.css"
import router from '@/router';
import { ElMessage } from 'element-plus';
import LoginServe from '@/views/components/LoginServe.vue';
import AudioPlayer from '@/views/components/AudioPlayer.vue';
import {AppData,MeetViewData,ViewID,LoginState, MeetViewDataParam} from '@/Common/AppData';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { HttpMethod, XhrPromiseState } from '@/Common/Manager/NetManager';
import { NetApi } from '@/Common/NetWork/NetApi';
import ImageShow, { ImageConfig } from './components/ImageShow.vue';
import { Utils } from '@/Common/Utils';
import * as MuiPlayer from "mui-player";
import { vi } from 'element-plus/es/locale';

@Options({
    components: {
        LoginServe,
        ImageShow,
        AudioPlayer
    }
})
export default class MarryView extends Vue {
    public get isMobile() {
        return AppData.instance.platFormIsMobile;
    }
    public viewData:MeetViewData;
    public loginServeActive:boolean = true;
    public loginState:LoginState = LoginState.None;

    public title = "";
    public imageHead: ImageConfig = null;
    public imageBase: ImageConfig[] = [];
    public peopledesc:PeopleDesc = {};
    public time: { t?: number, h?: number, m?: number, s?: number } = {};
    public continueTime: TimerStruct = { count: 3, id: null };

    public viewId:ViewID = ViewID.MeetView;
    public show: boolean = true;
    public store: MeetStoreGetter = useStore().getters;
    public router = useRouter();

    public bgMusic: string = "";
    private outTimer: number;

    private nextPage: string = "/";

    private mainContent:HTMLElement;
    private onlyOneTarget:string;
    private audioPLayer:AudioPlayer;

    private video:any[] = [];

    public override async mounted() {
        Utils.SetFavIcon("./res/favicon/meet_view.ico");
        this.mainContent = <HTMLElement>this.$refs.mainContent;
        let initTimer = setInterval(async ()=>
        {
            this.viewData = AppData.instance.GetAppData(this.viewId) as MeetViewData;
            if(AppData.instance.InitOk && this.viewData)
            {
                this.loginServeActive = false;
                clearInterval(initTimer);
                this.show = true;
                if (this.viewData.isOpen)
                {
                    if(this.viewData.hasLogin)
                    {
                       if(!this.store.token)
                       {
                            this._CalcOpenTimer();
                            this.loginState = LoginState.Failed;
                            this.mainContent.style.display = 'flex';
                            return;
                       }
                    }
                    else
                    {
                       this.loginState = LoginState.OK;
                       this.mainContent.style.display = 'inline';
                    }
                    let data = await this.GetViewData();
                    if (data) {
                      
                        this.title =data.title;
                        this.peopledesc = data.peopleDesc || {};
                        this.imageHead = data.imageBase.slice(0, 1)[0];
                        this.imageBase = data.imageBase.slice(1);
                        window.navigator.geolocation.getCurrentPosition((pos) => {
                            AppData.instance.NetWork.Http.HttpRequest(HttpMethod.POST, NetApi.POST_UploadPosition, { 
                                onlyOneTarget: this.onlyOneTarget, 
                                lng: pos.coords.longitude, 
                                lat: pos.coords.latitude 
                            });
                        }, () => {
                            AppData.instance.NetWork.Http.HttpRequest(HttpMethod.POST, NetApi.POST_UploadPosition, { 
                                onlyOneTarget: this.onlyOneTarget, 
                                lng: 0, 
                                lat: 0 });
                        })
                    }
                    else
                    {
                        this._CalcOpenTimer();
                    }
                }
            }
        },100);
        window['imageShow'] = [];
        window.onresize = () => {
            for (let i = 0; i < window['imageShow'].length; ++i) {
                window['imageShow'][i].reSize();
            }
        }
    }

    private _CalcOpenTimer()
    {
        let endT = new Date(this.store.meetData.endTime).getTime();
        if(endT < new Date().getTime())
        {
            endT = new Date(this.store.meetData.nextTime).getTime();
        }
        let t = new Date(endT - Date.now());
        if(endT - Date.now() < 0)
        {
            this.time = null;
        }
        else
        {
            let today = Math.floor(t.getTime() / 86400000);
            this.time = {
                t: today,
                h: t.getUTCHours(),
                m: t.getUTCMinutes(),
                s: t.getUTCSeconds()
            }
        }
        this.calcTimer = setInterval(() => {
            let t = new Date(endT - Date.now());
            let today = Math.floor(t.getTime() / 86400000);
            if(endT - Date.now() < 0)
            {
                this.time = null;
                return;
            }
            this.time = {
                t: today,
                h: t.getUTCHours(),
                m: t.getUTCMinutes(),
                s: t.getUTCSeconds()
            }
        }, 1000);
        this.ToView("/login");
    }

    public ViewAnimationComplate()
    {

    }

    public ToView(path: string, isNow: boolean = false) {
        clearTimeout(this.outTimer);
        clearInterval(this.continueTime.id);
        this.nextPage = path;
        if (isNow) {
            this.show = false;
            return
        }
        this.continueTime.id = setInterval(() => {
            this.continueTime.count--;
            if (this.continueTime.count == 0) {
                clearInterval(this.continueTime.id);
            }
        }, 1000)
        this.outTimer = setTimeout(() => {
            this.show = false;
        }, 3000);
    }

    private calcTimer: number;
    
    private GetViewData()
    {
        return new Promise<ViewData>(async (resolve)=>
        {
            let request = async ()=>
            {
                let data = await AppData.instance.NetWork.Http.HttpRequest(HttpMethod.POST, NetApi.POST_MeetViewImageData,null,true);
                if(data.xhrState === XhrPromiseState.RESOLVE)
                {
                    if(data.serverData.resultCode === 0)
                    {
                        let netData = data.serverData.data;
                        this.loginState = LoginState.OK; 
                        this.mainContent.style.display = 'inline';
                        this.title = netData.title;
                        this.peopledesc = netData.peopleDesc||{};
                        this.imageHead = netData.imageBase.slice(0,1)[0];
                        this.imageBase = netData.imageBase.slice(1);
                        this.onlyOneTarget = netData.only_one_target;
                        this.bgMusic = this.viewData.param.bg_music;
                        (this.viewData.param as MeetViewDataParam).video.forEach((video,key) => {
                            if(this.video[key])
                            {
                                this.video[key].destroy();
                            }
                            this.video[key] = new MuiPlayer({
                                container:`#video_${key+1}`,
                                title:video.title,
                                src:video.res,
                                poster:video.poster
                            });
                            
                            let videoComp = this.video[key];
                            videoComp.on('ready',()=>
                            {
                                videoComp.video().addEventListener('play',()=>
                                {
                                    this.video.forEach((element)=>
                                    {
                                        if(element !== videoComp)
                                        {
                                            element.video().pause();
                                        }
                                    });
                                    this._PauseAudioPlayer();
                                });
                                videoComp.video().addEventListener('pause',()=>
                                {
                                    let pause = this.video.length;
                                    setTimeout(()=>
                                    {
                                        this.video.forEach((element)=>
                                        {
                                            if((element.video() as HTMLVideoElement).paused)
                                            {
                                                if(--pause === 0 && this.video.length !== 0)
                                                {
                                                    this._ResumeAudioPlayer();
                                                }
                                            }
                                        });
                                    },16);
                                });
                                videoComp.video().addEventListener('ended',()=>
                                {
                                    let pause = this.video.length;
                                    setTimeout(()=>
                                    {
                                        this.video.forEach((element)=>
                                        {
                                            if((element.video() as HTMLVideoElement).paused)
                                            {
                                                if(--pause === 0 && this.video.length !== 0)
                                                {
                                                    this._ResumeAudioPlayer();
                                                }
                                            }
                                        });
                                    },16);
                                });
                            })
                           
                        });
                        this.audioPLayer = this.$refs.audioPLayer as any;
                        resolve(netData);
                    }
                    else if(data.serverData.resultCode === 401 || data.serverData.resultCode === -1)
                    {
                        ElMessage.warning(data.serverData.msg);
                        this.loginState = LoginState.Failed;
                        this.mainContent.style.display = 'flex';
                        resolve(null);
                    }
                    else if(data.serverData.resultCode === 10001)
                    {
                        ElMessage.warning(data.serverData.msg);
                        this.loginState = LoginState.Lock;
                        resolve(null);
                    }
                }
                else
                {
                    ElMessage.warning(data.serverData.msg||"服务器繁忙");
                    setTimeout(()=>
                    {
                        request();
                    },3000)
                }
            }
            request();
        })
    }
    public destoryView() {
        this.video.forEach((video)=>
        {
            video.destroy();
        });
        this.video = [];
        router.push(this.nextPage);
    }
    override unmounted() {
        clearTimeout(this.outTimer);
        clearInterval(this.continueTime.id);
        clearInterval(this.calcTimer);
    }

    private _ResumeAudioPlayer()
    {
        this.audioPLayer.PlayAuto();
    }

    private _PauseAudioPlayer()
    {
        this.audioPLayer.PausedAudio();
    }
}

interface PeopleDesc
{
    image?:
    {
        base?:
        {
            left:string;
            right:string;
        }
        top?:
        {
            left:string;
            right:string;
        }
    };
    title?:
    {
        str:string;
        content?:string
    };
    leftHead?:{
        url?:string;
        title?:string;
        content?:string[]
    };
    rightHead?:
    {
        url?:string;
        title?:string;
        content?:string[]
    }
}
interface ViewData
{
    bgMusic:string;
    title:string;                    
    peopleDesc?:PeopleDesc;
    imageHead:ImageConfig;                      
    imageBase:ImageConfig[];
    onlyOneTarget:any;
}
export type TimerStruct={count:number,id:number};
