import {AppData} from "./AppData";

export enum LogColor
{
    GREEN = "#00ff66",
    RED = "#FF0000",
    BLUE = "#0000FF",
    YELLOW = "#FFBB33"
}
export default class Log
{
    public static D(tag:string, ...args:any[])
    {
        return new Promise<void>((resolve)=>{
            if(AppData.instance.Debug)
            {
                console.log(tag,...args);
            }
            resolve();
        });
    }
    public static E(tag:string, ...args:any[])
    {   
        return new Promise<void>((resolve)=>{
            if(AppData.instance.Debug)
            {
                console.error(tag,...args);
            }
        });
       
    }
    public static W(tag:string, ...args:any[])
    {   
        return new Promise<void>((resolve)=>{
            if(AppData.instance.Debug)
            {
                console.warn(tag,...args);
            }
        }); 
    }
    public static DColor(msg:string,color:string|LogColor)
    {   
        return new Promise<void>((resolve)=>{
            if(AppData.instance.Debug)
            {
                if(msg)
                {
                    console.log(`%c${msg}`,`color:${color}`);
                }
                else
                {
                    console.warn(`Log===>Message Is Null`);
                }
            }
        });  
    }
}
