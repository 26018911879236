
import store, { StoreGetter } from '@/store';
import { Options, Vue } from 'vue-class-component'

import "../css/Temp/temp.css";

import { ElButton, ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus'
//import LoginServe from "../components/LoginServe.vue";
import { HTTP } from '@/Common/Manager/NetManager';
import { tr } from 'element-plus/es/locale';
import {AppData} from '@/Common/AppData';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getCurrentInstance } from 'vue';
// @Options({
//   components: {
//     LoginServe
//   }
// })
export default class TempView extends Vue {
    public get isMobile()
    {
        return AppData.instance.platFormIsMobile;
    }
    public store:StoreGetter  = useStore().getters;
    public router  = useRouter();
    public textareaIn:string = "";
    public textareaOut:string = "";
    public inputAdd:string = ""

    public show: boolean = false;
    public override mounted() 
    {
        this.show = true;
    }
    public override unmounted() {
        ElMessage.closeAll();
    }

    public finishView() {
      
    }

    public destoryView() {
        this.$router.push("/");
    }

    public ToHomeView()
    {
        this.$router.push("/");
    }

    public async OutPutText() {
        setTimeout(()=>
        {
            this.textareaOut = this.textareaIn.replaceAll("\n",`${this.inputAdd}\n`);
        },100)
    }
}
