import { Router } from "vue-router";
export default class RouterController{
   private router:Router;
   public constructor(router:Router){
        this.router = router;
        this.loadController();
   }
   private loadController(){
        this.router.beforeEach((to,form,next)=>{
            next();
        })
   }
}