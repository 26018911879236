import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "image-show",
  ref: "self"
}
const _hoisted_2 = {
  key: 0,
  class: "title",
  ref: "title"
}
const _hoisted_3 = { class: "line" }
const _hoisted_4 = {
  key: 0,
  ref: "lineLeft",
  class: "line-style"
}
const _hoisted_5 = {
  key: 1,
  ref: "lineRight",
  class: "line-style"
}
const _hoisted_6 = {
  key: 1,
  class: "title-desc",
  ref: "titleDesc"
}
const _hoisted_7 = {
  key: 2,
  ref: "imgParrent"
}
const _hoisted_8 = {
  class: "image-touch",
  ref: "imgBody"
}
const _hoisted_9 = {
  class: "left",
  ref: "leftArrow"
}
const _hoisted_10 = {
  class: "scroll-view",
  ref: "view"
}
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  class: "right",
  ref: "rightArrow"
}
const _hoisted_14 = {
  key: 3,
  class: "bottom-tip",
  ref: "bottomTip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imageData.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.imageData.title.lineWidth)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, null, 512))
              : _createCommentVNode("", true),
            _createElementVNode("h4", { ref: "titleText" }, _toDisplayString(_ctx.imageData.title.str), 513),
            (_ctx.imageData.title.lineWidth)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, null, 512))
              : _createCommentVNode("", true)
          ])
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.imageData.desc)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageData.desc.str, (titleItem, index) => {
            return (_openBlock(), _createElementBlock("h4", {
              key: index,
              class: "title-child"
            }, _toDisplayString(titleItem), 1))
          }), 128))
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.imageData.imageList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", _hoisted_9, "《--", 512),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageData.imageList, (imageItem, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "page",
                  key: index
                }, [
                  (!index)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "image",
                        src: imageItem,
                        ref_for: true,
                        ref: "imageHead"
                      }, null, 8, _hoisted_11))
                    : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        class: "image",
                        src: imageItem
                      }, null, 8, _hoisted_12)),
                  (!index && _ctx.imageData.imageContent.desc)
                    ? (_openBlock(), _createElementBlock("h4", {
                        key: 2,
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ToUrl && _ctx.ToUrl(...args))),
                        class: "image-desc",
                        ref_for: true,
                        ref: "imageDesc"
                      }, "         " + _toDisplayString(_ctx.imageData.imageContent.desc), 513))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ], 512),
            _createElementVNode("button", _hoisted_13, "--》", 512)
          ], 512)
        ], 512))
      : _createCommentVNode("", true),
    (_ctx.imageData.bottomTip)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageData.bottomTip, (titleItem, index) => {
            return (_openBlock(), _createElementBlock("h4", {
              key: index,
              class: "title-child"
            }, _toDisplayString(titleItem), 1))
          }), 128))
        ], 512))
      : _createCommentVNode("", true)
  ], 512))
}