
import {
    Options,
    Vue
} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { useStore } from 'vuex';
import { StoreGetter } from '@/store';
import { useRouter } from 'vue-router';
import {AppData} from '@/Common/AppData'
import { Utils } from '@/Common/Utils';

@Options({
    components: {
        HelloWorld,
    },
})
export default class HomeView extends Vue {
    public active: boolean = false;
    public store:StoreGetter  = useStore().getters;
    public router  = useRouter();
    public get isMobile()
    {
        return AppData.instance.platFormIsMobile;
    }
    
    public override mounted()
    {
        Utils.SetFavIcon("./res/favicon/main.ico");
        if(AppData.instance.needLogin)
        {
            this._CheckLogin();
        }
        this.active = true;
    }
    private _CheckLogin()
    {
        if(!this.store.token)
        {
            this.router.push("/login");
        }
    }
}
