import { MeetViewData } from '@/Common/AppData';
import { LoginApiParam } from '@/Common/NetWork/NetApi';
import { LocalUserConstrator, UserLocal } from '@/Common/UserData'
import { createStore } from 'vuex'

export interface StoreGetter {
  token: string,
}
export interface MeetStoreGetter extends StoreGetter
{
  meetData: MeetViewData;
}
export default createStore({
  state: 
  {
    LocalUser: UserLocal.instacne,
    meet: MeetViewData.instacne
  },
  getters:
  {
    token: state => state.LocalUser.token,
    meetData:state=>state.meet
  },
  mutations: 
  {
    token(state, value: string) 
    {
      state.LocalUser.token = value;
    },
    localUser(state,localUserConstrator:LocalUserConstrator)
    {
      state.LocalUser.SetUserInfo(localUserConstrator);
    }
  },
  actions: 
  {
    async login(state, loginApiParam: LoginApiParam) 
    {
      state.commit("localUser",null);
    }
  },
  modules: {
    
  }
})
