
import "../../css/components/loginServe/login-serve.css";
import { Vue, Options } from 'vue-class-component';
import store, { StoreGetter } from "@/store";
import { AppData, ViewID } from "@/Common/AppData";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

@Options({
    props: {
        success: Function,
        view: 0,
    }
})
export default class LoginServe extends Vue {
    public success: Function;
    public view: ViewID;
    centerDialogVisible: boolean = true;
    msg: string = "正在验证是否需要登陆...";
    public store: StoreGetter = useStore().getters;
    public router = useRouter();
    public override unmounted()
    {
        this.HideLoadAnim();
    }
    public override mounted()
    {
        this.ShowLoadAnim();
        let timer = 0,timerCheck = 0;
        timer = setInterval(()=>
        {
            if (AppData.instance.GetAppData(this.view))
            {
                if (AppData.instance.InitOk) {
                    clearInterval(timer);
                    clearInterval(timerCheck);
                    timerCheck = null;
                    this.success?.();
                }     
            }
            else
            {
                if(timerCheck)
                {
                    return;
                }
                timerCheck = setInterval(() => {
                    if (this.msg === "这比预计的时间要长,请稍后") {
                        this.msg = "正在验证是否需要登陆...";
                    }
                    else
                    {
                        this.msg = "这比预计的时间要长,请稍后";
                    }
                }, 5000);
            }
        },15);
    }

    private ShowLoadAnim()
    {
        setTimeout(()=>
        {
            let load = document.querySelector(".load");
            if (!load) return;
            for (let i = 0; i < load.children.length; ++i) {
                if (load.children[i].className.substr(0, 3) == 'dot') {
                    (load.children[i] as any).style.animationPlayState = "running";
                    (load.children[i] as any).style.display = 'inline';
                }
            }
        },16)
        
    }
    private  HideLoadAnim() 
    {
        setTimeout(() => {
            let load = document.querySelector(".load");
            if (!load) return;
            for (let i = 0; i < load.children.length; ++i) {
                if (load.children[i].className.substr(0, 3) == 'dot') {
                    (load.children[i] as any).style.animationPlayState = "paused";
                    (load.children[i] as any).style.display = 'none';
                }
            }
        }, 0);
    }
}
