import { Action } from "./Action";
import Log from "./Log";

export class Utils {
    public static SetFavIcon(path:string)
    {
        let favicon = <any>document.querySelector('link[rel="icon"]');
        if(favicon !== null)
        {
            favicon.href = path;
        }
        else
        {
            favicon = document.createElement("link");
            favicon.rel = "icon";
            favicon.href = path;
            document.head.appendChild(favicon);
        }
       
    }
    public static isJsonStr(jsonStr: string) {
        let obj = null;
        try
        {
            obj = JSON.parse(jsonStr);
        } catch (e)
        {
            return null;
        }
        return obj;
    }
    
    public static GetLocalTimeStr(timeStamp:number)
    {
        let time = timeStamp !== undefined ? new Date(timeStamp):new Date();
        return `${(time.getMonth()+1)<10?'0'+(time.getMonth()+1):(time.getMonth()+1)}/${time.getDate()<10?'0'+time.getDate():time.getDate()}  ${time.getHours()}:${time.getMinutes()}`;
    }

    public static NumberToChineseNumber(n) 
    {
        let digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
        let unit = [["","万", "亿"], ["", "拾", "佰", "仟"]];
        let head = n < 0 ? "负" : "";
        n = Math.abs(n);
        let s = "";
        n = Math.floor(n);
        for (let i = 0; i < unit[0].length && n > 0; i++) 
        {
            let p = "";
            for (let j = 0; j < unit[1].length && n > 0; j++)
            {
                p = digit[n % 10] + unit[1][j] + p;
                n = Math.floor(n / 10);
            }
            s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
        }
        return head+s.replace(/(零.)+/g, "零");
    }

    public static NumberIsEqure(num:number,tg:number)
    {
        return (num-tg) < Number.EPSILON;
    }
    
    public static GetUrlParam(key:string)
    {
        let url = location.href.toString(); //获取的完整url
        let u = url.split("?");
        if(typeof(u[1]) == "string"){
            u = u[1].split("&");
            var get = {};
            for(var i in u){
                var j = u[i].split("=");
                get[j[0]] = j[1];
            }
            return get[key]||null;
        } else {
            return null;
        }
    }

    public static GetToFixedNumber(value:number,count:number = 2):number
    {
        if(Number.isNaN(value))
        {
            Log.W("Utils===>>>GetToFixedNumber Value Is Null");
            return 0;
        }
        return Number(value.toFixed(count));
    }

    public static GetRandomRange(min:number,max:number)
    {
        if(min > max)
        {
            Log.W(`Utils===>GetRandomRange min:${min} > max:${max}`);
            return 0
        }
        return Math.floor(Math.random()*(max-min))+min;
    }

    public static Delay(time:number,cancleToken:CancelToken)
    {
        return new Promise<void>((reslove)=>
        {
            let i = 0;
            let t = setInterval(()=>
            {
                if(cancleToken.cancel)
                {
                    cancleToken.cancelAction.Invoke();
                   
                    reslove();
                    clearInterval(t);
                }
                i += 10;
                if(i >= time)
                {
                    
                    reslove();
                    clearInterval(t);
                }
            },10);
        });
    }

    public static DecodeUnicode(data:number) 
    {
        return String.fromCharCode(data);;
    }

    public static GetRandomNickName(length:number = Utils.GetRandomRange(2,5))
    {
        let name = "";
        for(let i = 0;i<length;i++){
            let unicodeNum = Utils.GetRandomRange(0x4e00,0x9fa5);
            name += Utils.DecodeUnicode(unicodeNum);
        }
        return name;
    }
}
export class CancelToken
{
    public cancel:boolean = false;
    public cancelAction:Action = new Action();
}

