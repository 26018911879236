
import store, { StoreGetter } from '@/store';
import { Options, Vue } from 'vue-class-component'

import "../css/Login/login.css";

import { ElButton, ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus'
//import LoginServe from "../components/LoginServe.vue";
import { HTTP } from '@/Common/Manager/NetManager';
import { tr } from 'element-plus/es/locale';
import {AppData} from '@/Common/AppData';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { getCurrentInstance } from 'vue';
// @Options({
//   components: {
//     LoginServe
//   }
// })
export default class LoginViewDeskTop extends Vue {
    public get isMobile()
    {
        return AppData.instance.platFormIsMobile;
    }
    public store:StoreGetter  = useStore().getters;
    public router  = useRouter();
    public userName: string = "";
    public passWord: string = "";
    public requestLoging: boolean = false;
    private nextTimer: number;
    private toNext: number;
    private time: { t?: number, h?: number, m?: number, s?: number } = {};
    public show: boolean = false;
    public override mounted() 
    {
        this.show = true;
    }
    public override unmounted() {
        ElMessage.closeAll();
        clearInterval(this.nextTimer);
        clearInterval(this.calcTimer);
    }
    private calcTimer: number;
    public finishView() {
        //    if (store.state.AppData.endTime) {
        //         let endT = new Date(store.state.AppData.endTime).getTime();
        //         let t = new Date(endT-Date.now());
        //         let today =  Math.floor(t.getTime()/86400000);
        //         this.time = {
        //             t:today,
        //             h:t.getUTCHours(),
        //             m:t.getUTCMinutes(),
        //             s:t.getUTCSeconds()
        //         }
        //         this.calcTimer = setInterval(() => {
        //                 let t = new Date(endT-Date.now());
        //                 let today =  Math.floor(t.getTime()/86400000);
        //                 this.time = {
        //                     t:today,
        //                     h:t.getUTCHours(),
        //                     m:t.getUTCMinutes(),
        //                     s:t.getUTCSeconds()
        //                 }
        //         }, 1000);  
        //         if(store.state.AppData.isLogin == HasLogin.NO){
        //             this.toNext = 3;
        //             this.nextTimer = setInterval(() => {
        //                 this.toNext--;
        //                 if (this.toNext == -1) {
        //                     clearInterval(this.nextTimer);
        //                     this.$router.push("/");
        //                 }
        //             }, 1000);
        //         }
        //    } 
    }
    public destoryView() {
        this.$router.push("/");
    }
    public async toPreview() {
        //    if(!this.userName || !this.passWord){
        //         ElMessage.error('账号密码不能为空');
        //         return;
        //    }
        //    this.requestLoging = true;
        //    let result = await HTTP.instance.Request('post','/login/user',{userName:this.userName,passWord:this.passWord}).catch(()=>{ElMessage.error("请求服务器失败")})
        //    if(!result){
        //        this.requestLoging = false;
        //        return;
        //    }
        //    if(result.data.resultCode == 0){
        //        ElMessage.success(`'${result.data.data.nickName}'欢迎回来,正在前往`);
        //        store.state.UserData.setToken(result.data.data.token);
        //        setTimeout(()=>{
        //            this.show = false;
        //        },1000)
        //    }else{
        //        ElMessage.error(result.data.msg);
        //        this.requestLoging = false;
        //    }    
    }
}
