import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus, { ElMessage } from "element-plus";
import {Edit,User,Lock} from "@element-plus/icons"
import RouterController from './router/RouterController'
import {AppConfig, AppData, ViewConfig} from './Common/AppData';
import HumanMaleIcon from 'vue-material-design-icons/HumanMale.vue';
import { HttpMethod, XhrPromiseState } from './Common/Manager/NetManager';
import { NetApi } from './Common/NetWork/NetApi';
import { da } from 'element-plus/es/locale';

// import VConsole from 'vconsole'


// //var vconsole = new VConsole();


var app = createApp(App);
App.created = async function(){
    AppData.instance.netApiUrlBase = "https://local.coolarr.com:23401";
    AppData.instance.socketUrl = "wss://local.coolarr.com:23400";
    store.commit("token",localStorage.getItem('userAccount'));
    AppData.instance.CerateNetWorkConnect();
    let Login = ()=>{
        return new Promise<ViewConfig[]>(async (resolve)=>
        {
            let data = await AppData.instance.NetWork.Http.HttpRequest(HttpMethod.GET,NetApi.GET_moudleConfig,null,true);
            if(data.xhrState === XhrPromiseState.RESOLVE)
            {
                if(data.serverData.resultCode === 0)
                {
                    let netData = data.serverData.data;
                    AppData.instance.SetAppConfig(netData.config);
                    resolve(netData.view_config);
                }
                else
                {
                    ElMessage.error(data.serverData.msg||"服务器繁忙");
                    setTimeout(()=>
                    {
                        Login();
                    },3);
                }
            }
            else
            {
                ElMessage.warning(data.serverData.msg||"服务器繁忙");
                setTimeout(()=>
                {
                    Login();
                },3);
            }
        })
    }     
    let config = await Login();
    AppData.instance.InitMoudle(config);
}
app.component(Lock.name,Lock);
app.component(User.name,User);
app.component(HumanMaleIcon.name,HumanMaleIcon);
app.use(ElementPlus);
app.use(store).use(router).mount('#app');
new RouterController(router);


