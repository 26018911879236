
import "../../css/components/audioPlayer/audio.css";
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { TimerStruct } from "../MarryView.vue";

@Options({
    props: {
        musicUrl: String,
        playOnLoad: Boolean
    }
})
export default class AudioPlayer extends Vue {
    playOnLoad: boolean;
    musicUrl: string;
    @Watch('musicUrl')
    musicChanged(newVal: string, oldVal: string) {
        this.setMusic();
    };
    private cd: HTMLElement = null;
    private audio: HTMLAudioElement = null;
    private head: HTMLElement = null;
    
    private delayTimer: TimerStruct = {
        count: 800,
        id: null
    }
    public override mounted() {
        this.cd = document.getElementById('cd');
        this.head = document.getElementById('head');
    }
    public setMusic() {
        if (!this.musicUrl) {
            return;
        }
        if (this.audio) { this.audio.pause(); this.audio.remove() };
        this.audio = new Audio(this.musicUrl);
        this.audio.loop = false;
        this.audio.oncanplay = this.loadOk.bind(this);
        this.audio.onerror = this.loadError.bind(this);
        this.audio.onplay = this.cdPlay.bind(this);
        this.audio.onpause = this.cdPaused.bind(this);
        this.audio.onended = () => {
            this.PausedAudio();
        };
    }
    private cdPlay() {
        clearTimeout(this.delayTimer.id);
        this.delayTimer.id = setTimeout(() => {
            this.cd.classList.add("play");
        }, this.delayTimer.count);
        this.head.classList.remove("headClose");
        this.head.classList.add("headStart")
    }
    private cdPaused() {
        clearTimeout(this.delayTimer.id);
        this.cd.classList.remove("play");
        this.head.classList.remove("headStart");
        this.head.classList.add("headClose")

    }
    private loadOk() {
        if (this.playOnLoad) {
            setTimeout(() => {
                this.PlayAuto();
            }, 1000);
        }
    }
    public playOrPaused() {
        if (this.audio && this.audio.readyState == 4) {
            if (this.audio.paused) {
                this.PlayAuto();
            } else {
                this.PausedAudio();
            }
        }
    }
    public PlayAuto() {
        if (this.audio) {
            this.audio.play();
        }
    }
    public PausedAudio() {
        if (this.audio) {
            this.audio.pause();
        }
    }
    private loadError() {
        if (this.audio) {
            this.audio.remove();
        }
    }
    public override unmounted() {
        this.PausedAudio();
        if (this.audio)
            this.audio.remove();
    }
}
