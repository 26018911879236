import {AppData} from "../AppData";
import Log from "../Log";
import { Utils } from "../Utils";

export enum HttpMethod {
    GET,
    POST
}
export enum ConnectType {
    JSON,
    BINUARY
}
interface RequestQueueData {
    xhr: XMLHttpRequest,
    resolve: (data?: any) => void;
}
export interface RequestHeader
{
    header:string,
    value:string;
}
export enum XhrPromiseState{
    REJECT,
    RESOLVE,
    ABORT,
    OUTTIMER
}
export interface HttpMessage {
    xhrState:XhrPromiseState,
    serverData: {data?:any,msg?:string,resultCode?:number};
}
export class HTTP {
    private host: string = "http://localhost";
    public token: string = "";
    public outTimeSec:number = 10;
    public getRequestHeader:()=>RequestHeader[];
    private requestQueue: RequestQueueData[] = [];
    constructor(host: string = null, port: number = null) {
        this.SetHost(host,port);
    }

    public SetHost(host: string = null, port: number = null)
    {
        if (host) {
            this.host = host;
        }
        if (port && port != 80) {
            this.host += `:${port}`;
        }
    }
    
    /**
     * @param method 请求方法
     * @param route 路由
     * @param param 参数
     * @param hasToken 是否携带token
     * @returns 异步返回错误失败请判定xhrState
     */
    public HttpRequest(method: HttpMethod, route: string, param: object = null, hasToken: boolean = true): Promise<HttpMessage> {
        return new Promise<HttpMessage>((resolve) => {
            let xhr = new XMLHttpRequest();
            route = this.host + route;
            Log.D("HTTP===>>>Request\n",route,JSON.parse(JSON.stringify(param)));
            let reqMethod = "",timer;
            // 对参数进行处理
            switch (method.toString()) {
                case '0':
                    let requestData = "";
                    if (param) {
                        requestData = Object.keys(param)
                            .map(key => {
                                return (
                                    encodeURIComponent(key) + '=' + encodeURIComponent(param[key])
                                )
                            })
                            .join('&')
                    }
                    route = requestData ? route + '?' + requestData : route;
                    reqMethod = 'GET';
                    break;
                case '1':
                    reqMethod = 'POST';
                    break;
            }

            xhr.timeout = this.outTimeSec*1000;
            xhr.onerror = ()=>
            {
                for (let i = 0; i < this.requestQueue.length; ++i) {
                    let requestQueueData = this.requestQueue[i];
                    if (requestQueueData.xhr === xhr) {
                        this.requestQueue.splice(i, 1);
                        break;
                    }
                }
                Log.D("HTTP===>>>Request Error\n",route,JSON.parse(JSON.stringify(param)));
                let data: HttpMessage = {
                    xhrState:XhrPromiseState.REJECT,
                    serverData:{msg:`请求失败`}
                };
                resolve(data);
            }
            xhr.ontimeout = ()=>
            {
                clearTimeout(timer);
                this.AbortRequest(xhr,XhrPromiseState.OUTTIMER,"服务器繁忙,请稍后再试!");
            }
            
            xhr.open(reqMethod, route, true);
            
            if (this.token && hasToken) {
                //xhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
                xhr.setRequestHeader('token', this.token);
            }

            if(this.getRequestHeader)
            {
                let header = this.getRequestHeader();
                header.forEach(element =>
                {
                    xhr.setRequestHeader(element.header,element.value);
                });
            }
            // 设置请求头
            switch (reqMethod) {
                case 'GET':
                    break;
                case 'POST':
                    xhr.setRequestHeader('Content-Type', 'application/json');
                    break;
            }
            xhr.onreadystatechange = () => {
                if(xhr.readyState == 4 && xhr.status === 0)
                {
                    Log.D("HTTP===>>>Request Warning 响应status为0 非标准 无法解析返回 请确认\n",route,JSON.parse(JSON.stringify(param)));
                }
                if (xhr.readyState == 4 && xhr.status !== 0) {
                    for (let i = 0; i < this.requestQueue.length; ++i) {
                        let requestQueueData = this.requestQueue[i];
                        if (requestQueueData.xhr === xhr) {
                            this.requestQueue.splice(i, 1);
                            break;
                        }
                    }
                    Log.D("HTTP===>>>Reponse\n",route,Utils.isJsonStr(xhr.responseText));
                    if (xhr.status >= 200 && xhr.status < 400)
                    {
                        let response = xhr.responseText;
                        let data: HttpMessage = {
                            xhrState:XhrPromiseState.RESOLVE,
                            serverData:Utils.isJsonStr(response)
                        } 
                        resolve(data);
                    }
                    else
                    {
                        let data: HttpMessage = {
                            xhrState:XhrPromiseState.REJECT,
                            serverData:{msg:`${xhr.status} 请求失败`}
                        };
                        resolve(data);
                    }
                }
            };
            xhr.send(reqMethod === "POST" ? JSON.stringify(param) : null);

            timer = setTimeout(()=>
            {
                this.AbortRequest(xhr,XhrPromiseState.OUTTIMER,"服务器繁忙,请稍后再试!");
            },this.outTimeSec*1000);
            this.requestQueue.push({ xhr, resolve});
        });
    }
    public AbortAllRequest() {
        if (this.requestQueue.length) {
            for (let i = 0; i < this.requestQueue.length; ++i) {
                let data: HttpMessage = {
                    xhrState:XhrPromiseState.ABORT,
                    serverData:null
                };
                this.requestQueue[i].xhr.abort();
                this.requestQueue[i].resolve(data);
            }
            this.requestQueue = [];
        }
    }

    public AbortRequest(xhr:XMLHttpRequest,state:XhrPromiseState,msg?:string) 
    {
        if (this.requestQueue.length) {
            let index = -1;
            for (let i = 0; i < this.requestQueue.length; ++i)
            {
                if(this.requestQueue[i].xhr === xhr)
                {
                    index = i;
                    let data: HttpMessage =
                    {
                        xhrState:state,
                        serverData:{msg},
                    };
                    this.requestQueue[i].resolve(data);
                    this.requestQueue[i] = null;
                }
            }
            if(index !== -1)
            {
                this.requestQueue.splice(index,1);
            }
        }
    }
}

export class Socket
{
    
}

export class NetManager
{

    public CreateHttp()
    {

    }

    public CreateSocket()
    {

    }
}