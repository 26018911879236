import LoginView from '@/views/LoginView.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TempView from '../views/TempView.vue'
import NotFound from '../views/NotFound.vue'
import MarryView from '../views/MarryView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component:LoginView
  },
  {
    path: '/temp',
    name: 'temp',
    component:TempView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/video',
    name: 'video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoView.vue')
  },
  {
    path: '/marry',
    name: 'marry',
    component: MarryView
  },
  {
    path:"/:catchAll(.*)",
    name:'notFound',
    component:NotFound
  }
]

//默认为空字符串 二级目录时 /二级目录/
//true?process.env.BASE_URL:'/web/site/
const router = createRouter({
  history: createWebHistory(true?process.env.BASE_URL:''),
  routes
})

export default router