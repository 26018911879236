import { pa } from "element-plus/es/locale";
import { HTTP, Socket } from "./Manager/NetManager";
export enum LoginState {
    None,
    OK,
    Failed,
    Lock
}
export interface NetWork
{
    Http:HTTP;
    Socket:Socket;
}
export class AppData
{
    public readonly appid:number = 0;
    public NetWork:NetWork = {
        Http:null,
        Socket:null
    }
    private _initOk:boolean = false;
    public get InitOk()
    {
        return this._initOk;
    }
    public Debug:boolean = true;
    public needLogin:boolean = false;
    public netApiUrlBase:string = "https://localhost";
    public socketUrl:string = "wss://localhost";
    private forcePlatformIsMobile = false;
    private _config:AppConfig = null;
    private static self:AppData;
    public appList:View[] = [];
    public CerateNetWorkConnect()
    {
        this.NetWork.Http = new HTTP(this.netApiUrlBase);
        this.NetWork.Socket = new Socket();
    }
    public SetAppConfig(config:AppConfig)
    {
        this._config = config;
    }
    public static get instance():AppData
    {
        if(!AppData.self)
        {
            AppData.self = new AppData();
        }
        return AppData.self;
    }
    public InitMoudle(config:ViewConfig[])
    {
        config.forEach(configData=>
        {
            if(configData.view_id === ViewID.MeetView)
            {
                this.appList[configData.view_id] = MeetViewData.instacne;
                let dataInstance:MeetViewData = this.appList[configData.view_id] as MeetViewData;
                dataInstance.isOpen = configData.open;
                dataInstance.param = configData.param;
                dataInstance.viewID = configData.view_id;
                dataInstance.hasLogin = configData.has_login;
                dataInstance.endTime = configData.end_time;
                dataInstance.nextTime = configData.next_time;
                dataInstance.openTime = configData.open_time;
            }
        });
        this._initOk = true;
    }
    public GetAppData(id:ViewID)
    {
        return this.appList[id];
    }
    public get platFormIsMobile()
    {
        if(this.forcePlatformIsMobile)
        {
            return this.forcePlatformIsMobile
        }
        if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
            return true;
        }
        return false;
    }
}
export enum ViewID
{
    MeetView = 1
}
export interface ViewConfig
{
    param:any;
    open:boolean;
    view_id:number;
    has_login:boolean;
    open_time:string;
    end_time:string;
    next_time:string;
}
export interface AppConfig
{
    open:boolean;
    view_id:number;
    moudle_name:string;
}
abstract class View
{
    protected abstract _param:any;
    public get param()
    {
        return this._param;
    }
    public set param(value:any)
    {
        this._param = value;
    }
    protected _isOpen:boolean = false;
    public get isOpen()
    {
        return this._isOpen
    }
    public set isOpen(value:boolean)
    {
        this._isOpen = value;
    }
    protected _hasLogin:boolean = true;
    public get hasLogin()
    {
        return this._hasLogin
    }
    public set hasLogin(value:boolean)
    {
        this._hasLogin = value;
    }
    protected _viewID:number = 0;
    public get viewID()
    {
        return this._viewID
    }
    public set viewID(value:number)
    {
        this._viewID = value;
    }
}

export type MeetViewDataParam = 
{
    bg_music:string,video:{title:string,res:string,poster:string}[]
}

export class MeetViewData extends View
{
    protected _param:MeetViewDataParam;
    private static self:MeetViewData;
    public static get instacne():MeetViewData
    {
        if(!MeetViewData.self)
        {
            MeetViewData.self = new MeetViewData();
        }
        return MeetViewData.self;
    }
    protected _openTime:string = "";
    public get openTime()
    {
        return this._openTime
    }
    public set openTime(value:string)
    {
        this._openTime = value;
    }
    protected _endTime:string = "";
    public get endTime()
    {
        return this._endTime
    }
    public set endTime(value:string)
    {
        this._endTime = value;
    }
    protected _nextTime:string = "";;
    public get nextTime()
    {
        return this._nextTime
    }
    public set nextTime(value:string)
    {
        this._nextTime = value;
    }
}