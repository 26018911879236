export interface LoginApiParam
{
    userName:string;
    password:string;
}

export const NetApi = 
{
    GET_moudleConfig:'/getMoudleContent',
    POST_MeetViewImageData:'/meetView/getImageData',
    POST_UploadPosition:'/meetView/gpsPos'
}